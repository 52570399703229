import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  TextInputFormField,
  IconAdornment,
} from '../../Shared/TextInputFormField';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { ReactComponent as WelcomeBackIcon } from '../../../images/icons/welcome_back_icon.svg';
import { useDispatch } from 'react-redux';
import { login } from '../../../store/auth/actionCreators';

const LoginFormCard = () => {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} p={16}>
      <WelcomeBackIcon />
      <Typography gutterBottom>Welcome back!</Typography>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(login(values.email, values.password));
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="email"
              type="email"
              label="Email"
              component={TextInputFormField}
              placeholder="Enter your email address"
            />
            <Field
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              placeholder="Enter your password"
              component={TextInputFormField}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <IconAdornment
                    onClick={handleClickShowPassword}
                    faIconProps={{
                      icon: showPassword ? faEyeSlash : faEye,
                    }}
                  />
                ),
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={4}
              mt={4}
            >
              <Link to="/auth/resetPassword">
                <Typography variant="body2" color="secondary">
                  Forgot Password?
                </Typography>
              </Link>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
              >
                Login
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LoginFormCard;
