import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { oAuthHost } from '../../apiConfig';
import {
  jsonToFormUrlEncoded,
  setCookie,
  formatUrl,
  getCookie,
  deleteToken,
} from '../../shared/utils';
import { AUTH_MESSAGES } from '../../shared/constants';
import {
  AccessTokenInterface,
  AuthStoreActionTypes,
  ChangePasswordInterface,
} from './authTypes';

export const apiError = (error: any) => ({
  type: AuthStoreActionTypes.AUTH_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: AuthStoreActionTypes.AUTH_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const logOut = (refresh_token: string) => {
  let params = jsonToFormUrlEncoded({ refresh_token: refresh_token });
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(oAuthHost, 'auth/logout'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .catch((err) => {
        console.error(err.response?.data?.message);
      })
      .finally(() => {
        deleteToken();
        dispatch(setLoadState(false));
      });
  };
};

export const successChangeUserPassword = (
  accessToken: AccessTokenInterface
) => ({
  type: AuthStoreActionTypes.SUCCESS_CHANGE_USER_PASSWORD,
  payload: { accessToken: accessToken },
});

export const changeUserPassword = (data: ChangePasswordInterface) => {
  let params = jsonToFormUrlEncoded(data);
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(oAuthHost, 'auth/changePassword'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(successChangeUserPassword(res.data));
        setCookie('access_token', res.data.access_token, res.data.expires_in);
        setCookie('refresh_token', res.data.refresh_token);
        setCookie('token_type', res.data.token_type);
        toast.success('Password changed successfully');
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          window.location.href = formatUrl(oAuthHost);
        }
        let message;
        if (err.response.status === 400) {
          message = 'Incorrect request, please try again';
        }
        if (err.response.status === 403) {
          message = 'Incorrect current password';
        }
        if (err.response.status === 500 || err.response.status === 503) {
          message = 'System down, please try again';
        }
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const resetCompleteAccessToken = () => ({
  type: AuthStoreActionTypes.RESET_COMPLETE_ACCESS_TOKEN,
});

export const loginSuccess = (accessToken: AccessTokenInterface) => ({
  type: AuthStoreActionTypes.LOGIN_SUCCESS,
  payload: { accessToken: accessToken },
});

export const login = (email: string, password: string) => {
  let params = jsonToFormUrlEncoded({
    username: email,
    password: password,
    grant_type: 'password',
    apiToken: '8SVVpNIGvBXZP2TECoj6LA0T3o3VApy85VbD', // ToDo : Replace the apiToken harcoded logic
  });
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(oAuthHost, 'auth/login'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        dispatch(loginSuccess(res.data));
        setCookie('access_token', res.data.access_token, res.data.expires_in);
        setCookie('refresh_token', res.data.refresh_token);
        setCookie('token_type', res.data.token_type);
        toast.success('Login successful');
        dispatch(setLoadState(false));
        window.location.href = '/';
      })
      .catch((err) => {
        const message = 'Incorrect email or password'; // This will be changed when migrated to Rest API call
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const resetPasswordRequest = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(oAuthHost, 'auth/forgetPasswordReq'), {
        params: { email: email },
      })
      .then((res) => {
        if (res.data.error) {
          // Handled case where API returns 200 but contains an error message
          toast.error(res.data.error);
          dispatch(apiError(res.data.error));
        } else {
          dispatch({
            type: AuthStoreActionTypes.RESET_PASSWORD_REQUEST,
            payload: { email, message: res.data.message },
          });
          toast.success(
            'A password reset email has been sent. If you didnt receive it, please check your spam folder or try again'
          );
        }
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        const message = 'Failed to send password reset request';
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const updateValidationTokenRequestMessage = (
  email: string,
  message: string
) => ({
  type: AuthStoreActionTypes.VALIDATE_TOKEN_REQUEST,
  payload: { email, message },
});

export const validateTokenRequest = (token: string, email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(oAuthHost, 'auth/validateToken'), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(updateValidationTokenRequestMessage(email, res.data.message));
        dispatch(setLoadState(false));
      })
      .catch(() => {
        const message = AUTH_MESSAGES.FAILED_TOKEN_VALIDATION;
        dispatch(updateValidationTokenRequestMessage(email, message));
        dispatch(apiError(message));
        dispatch(setLoadState(false));
      });
  };
};

export const updateResetPasswordMessage = (email: string, message: string) => ({
  type: AuthStoreActionTypes.RESET_PASSWORD,
  payload: { email, message },
});

export const resetPassword = (
  email: string,
  newPassword: string,
  token: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('new_password', newPassword);

    return axios
      .post(formatUrl(oAuthHost, 'auth/resetPassword'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(updateResetPasswordMessage(email, res.data.message));
        toast.success('Password reset successful');
        dispatch(setLoadState(false));
        window.location.href = '/auth/login';
      })
      .catch((err) => {
        const message = 'Failed to reset password';
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const updateVerifyEmailMessage = (email: string, message: string) => ({
  type: AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST,
  payload: { email, message },
});

export const validateEmailRequest = (token: string, email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    const params = new URLSearchParams();
    params.append('email', email);

    return axios
      .post(formatUrl(oAuthHost, 'register/verify'), params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          dispatch(
            updateVerifyEmailMessage(
              email,
              AUTH_MESSAGES.EMAIL_VERIFICATION_SUCCESS
            )
          );
        } else {
          throw new Error(response.data.message || 'Unknown error');
        }
        dispatch(setLoadState(false));
      })
      .catch(() => {
        const message = AUTH_MESSAGES.FAILED_TOKEN_VALIDATION;
        dispatch(updateVerifyEmailMessage(email, message));
        dispatch(apiError(message));
        dispatch(setLoadState(false));
      });
  };
};

export const emailVerificationRequest = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(oAuthHost, 'register/verificationReq'), {
        params: { email: email },
      })
      .then((res) => {
        if (res.data.error) {
          // Handled case where API returns 200 but contains an error message
          toast.error(res.data.error);
          dispatch(apiError(res.data.error));
        } else {
          toast.success(
            'A new email to verify your account has been sent. If you didnt receive it, please check your spam folder or try again'
          );
        }
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        const message = 'Failed to send email verification request';
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};
