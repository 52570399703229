import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Stack, Box } from '@mui/material';
import { styled } from '@mui/system';
import loginImage from '../../images/om_care_auth_layout.png';
import { lightBackground1 } from '../../mainTheme';
import navLogo from '../../images/omcare_side_nav_logo.png';
import { getCookie } from '../../shared/utils';

interface AuthLayoutProps {
  children: React.ReactNode;
  showOmcareLogo?: boolean;
}
const OmcareLogo = styled('img')({
  height: '30px',
});

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  showOmcareLogo,
}) => {
  if (getCookie('access_token')) {
    return <Redirect to="/" />;
  }

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        height: '100vh',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: { xs: 2, sm: 4 },
          maxWidth: { md: '35%' },
          maxHeight: '100%',
          overflow: 'hidden',
          flexShrink: 1,
          position: 'relative',
          overflowY: 'auto', // Enable vertical scrolling if needed
        }}
        bgcolor={lightBackground1}
      >
        {showOmcareLogo && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              m: 2,
            }}
          >
            <Link to="/">
              <OmcareLogo src={navLogo} alt="OmcareLogo" />
            </Link>
          </Box>
        )}
        {children}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: { xs: 'none', md: 'block' },
          backgroundImage: `url(${loginImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right center',
        }}
      />
    </Stack>
  );
};

export default AuthLayout;
