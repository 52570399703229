import { Reducer } from 'redux';
import { AuthStore, AuthStoreAction, AuthStoreActionTypes } from './authTypes';

const initialState: AuthStore = {
  completeAccessToken: null,
  error: null,
  isLoading: false,
  validationMessage: null,
};

const authReducer: Reducer<AuthStore, AuthStoreAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AuthStoreActionTypes.SUCCESS_CHANGE_USER_PASSWORD: {
      const { accessToken } = action.payload;
      let newState = {
        ...state,
        completeAccessToken: accessToken,
      };
      return newState;
    }
    case AuthStoreActionTypes.RESET_COMPLETE_ACCESS_TOKEN: {
      let newState = {
        ...state,
        completeAccessToken: initialState.completeAccessToken,
      };
      return newState;
    }
    case AuthStoreActionTypes.AUTH_API_ERR: {
      const { error } = action.payload;
      console.log('error: ', action.payload);
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case AuthStoreActionTypes.AUTH_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        isLoading: isLoading,
      };
      return newState;
    }
    case AuthStoreActionTypes.LOGIN_SUCCESS: {
      const { accessToken } = action.payload;
      let newState = {
        ...state,
        completeAccessToken: accessToken,
      };
      return newState;
    }
    case AuthStoreActionTypes.RESET_PASSWORD_REQUEST: {
      const { email, message } = action.payload;
      let newState = {
        ...state,
        validationMessage: {
          type: AuthStoreActionTypes.RESET_PASSWORD_REQUEST,
          email,
          message,
        },
      };
      return newState;
    }
    case AuthStoreActionTypes.VALIDATE_TOKEN_REQUEST: {
      const { email, message } = action.payload;
      let newState = {
        ...state,
        validationMessage: {
          type: AuthStoreActionTypes.VALIDATE_TOKEN_REQUEST,
          email,
          message,
        },
      };
      return newState;
    }
    case AuthStoreActionTypes.RESET_PASSWORD: {
      const { email, message } = action.payload;
      let newState = {
        ...state,
        validationMessage: {
          type: AuthStoreActionTypes.RESET_PASSWORD,
          email,
          message,
        },
      };
      return newState;
    }
    case AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST: {
      const { email, message } = action.payload;
      let newState = {
        ...state,
        validationMessage: {
          type: AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST,
          email,
          message,
        },
      };
      return newState;
    }
    default:
      return state;
  }
};

export default authReducer;
