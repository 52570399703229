import React from 'react';
import { Switch, FormControlLabel, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setToggleUI } from '../../store/uiToggle/actionCreators';

const UIToggleSwitch: React.FC = () => {
  const useNewUI = useSelector((state: RootState) => state.uiToggle.useNewUI);
  const dispatch = useDispatch();

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setToggleUI(event.target.checked));
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={useNewUI}
          onChange={handleToggleChange}
          color="secondary"
        />
      }
      label={
        useNewUI ? (
          <Typography>Classic</Typography>
        ) : (
          <Typography>Modern</Typography>
        )
      }
      labelPlacement={useNewUI ? 'start' : 'end'}
    />
  );
};

export default UIToggleSwitch;
