export interface AccessTokenInterface {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export interface ChangePasswordInterface {
  current_password: string;
  new_password: string;
}

export interface ValidationContext {
  type: string;
  email: string;
  message: string;
}

export interface AuthStore {
  completeAccessToken: AccessTokenInterface | null;
  error: any | null;
  isLoading: boolean;
  validationMessage: ValidationContext | null;
}

export enum AuthStoreActionTypes {
  SUCCESS_CHANGE_USER_PASSWORD = 'SUCCESS_CHANGE_USER_PASSWORD',
  AUTH_API_ERR = 'AUTH_API_ERR',
  RESET_COMPLETE_ACCESS_TOKEN = 'RESET_COMPLETE_ACCESS_TOKEN',
  AUTH_SET_LOAD_STATE = 'AUTH_SET_LOAD_STATE',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  VALIDATE_TOKEN_REQUEST = 'VALIDATE_TOKEN_REQUEST',
  RESET_PASSWORD = 'RESET_PASSWORD',
  VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST',
}

export type SuccessChangeUserPasswordType = {
  type: AuthStoreActionTypes.SUCCESS_CHANGE_USER_PASSWORD;
  payload: { accessToken: AccessTokenInterface };
};

export type ResetCompleteAccessTokenType = {
  type: AuthStoreActionTypes.RESET_COMPLETE_ACCESS_TOKEN;
};

export type ApiErrorType = {
  type: AuthStoreActionTypes.AUTH_API_ERR;
  payload: { error: any };
};

export type SetLoadStateType = {
  type: AuthStoreActionTypes.AUTH_SET_LOAD_STATE;
  payload: { isLoading: boolean };
};

export type LoginSuccessType = {
  type: AuthStoreActionTypes.LOGIN_SUCCESS;
  payload: { accessToken: AccessTokenInterface };
};

export type ResetPasswordRequestType = {
  type: AuthStoreActionTypes.RESET_PASSWORD_REQUEST;
  payload: { email: string; message: string };
};

export type ValidateTokenRequestType = {
  type: AuthStoreActionTypes.VALIDATE_TOKEN_REQUEST;
  payload: { email: string; message: string };
};

export type ResetPasswordType = {
  type: AuthStoreActionTypes.RESET_PASSWORD;
  payload: { email: string; message: string };
};

export type ValidateEmailRequestType = {
  type: AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST;
  payload: { email: string; message: string };
};

export type AuthStoreAction =
  | SuccessChangeUserPasswordType
  | ResetCompleteAccessTokenType
  | ApiErrorType
  | SetLoadStateType
  | LoginSuccessType
  | ResetPasswordRequestType
  | ValidateTokenRequestType
  | ResetPasswordType
  | ValidateEmailRequestType;
