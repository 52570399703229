import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import PasswordCriteria from './PasswordCriteria'; // Adjust the import path as necessary
import { lightBlack } from '../../../../mainTheme';

interface PasswordStrengthCheckerProps {
  password: string;
}

const PasswordStrengthChecker: React.FC<PasswordStrengthCheckerProps> = ({
  password,
}) => {
  const criteria = [
    { label: 'Lower case', isValid: /[a-z]/.test(password) },
    { label: 'Capital case', isValid: /[A-Z]/.test(password) },
    { label: 'Number', isValid: /\d/.test(password) },
    // ToDo :  when rest api placed in replace NOT operator validation check
    {
      label: 'No Special character',
      isValid: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    { label: '8 characters', isValid: password.length >= 8 },
  ];

  return (
    <div>
      <Box mb={2}>
        <Typography variant="caption" color={lightBlack}>
          Your password must contain:
        </Typography>
      </Box>

      <Grid container spacing={2} wrap="wrap">
        {criteria.map((criterion) => (
          <Grid item xs={12} sm={6} md={'auto'} key={criterion.label}>
            <PasswordCriteria
              label={criterion.label}
              isValid={criterion.isValid}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PasswordStrengthChecker;
