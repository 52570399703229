import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import { ReactComponent as ResetPasswordIcon } from '../../../images/icons/reset_password_icon.svg';
import { useHistory } from 'react-router-dom';
import { lightBlack } from '../../../mainTheme';
import { useDispatch } from 'react-redux';
import { resetPasswordRequest } from '../../../store/auth/actionCreators';

const ResetPasswordFormCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  const handleReturn = () => {
    history.push('/auth/login');
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} p={16}>
      <ResetPasswordIcon />
      <Typography>Reset password</Typography>
      <Typography color={lightBlack}>
        Enter the email address associated with your account. You&apos;ll then
        receive an email to reset your password.
      </Typography>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          dispatch(resetPasswordRequest(values.email));
          resetForm(); // Reset the form field after submission
        }}
      >
        {({ handleSubmit, isValid, values }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="email"
              type="email"
              label="Email"
              component={TextInputFormField}
              placeholder="Enter your email address"
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ mt: 4 }}
              disabled={!values.email || !isValid}
            >
              Reset password
            </Button>
            <Box mt={16}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleReturn}
              >
                Return
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ResetPasswordFormCard;
