import React, { useEffect, useState } from 'react';
import { ReactComponent as WelcomeBackIcon } from '../../../images/icons/welcome_back_icon.svg';
import { Box, Typography, Button, TextField, IconButton } from '@mui/material';
import { lightBlack } from '../../../mainTheme';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordStrengthChecker from './PasswordStrengthChecker/PasswordStrengthChecker';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetPassword,
  validateTokenRequest,
} from '../../../store/auth/actionCreators';
import { AuthStoreActionTypes } from '../../../store/auth/authTypes';
import { RootState } from '../../../store';
import WarningModal from './WarningModal';
import { AUTH_MESSAGES } from '../../../shared/constants';

const CreatePasswordCard = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const validationMessage = useSelector(
    (state: RootState) => state.auth.validationMessage
  );

  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get('access_token');
  const email = queryParams.get('email');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isExpWarnModalOpen, setExpWarnModalOpen] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Minimum 8 characters')
      .matches(/[a-z]/, 'Contains lowercase letter')
      .matches(/[A-Z]/, 'Contains uppercase letter')
      .matches(/\d/, 'Contains number')
      .test('no-special-char', 'Contains special character', (value) => {
        return !/[!@#$%^&*(),.?":{}|<>]/.test(value || '');
      })
      // .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contains special character') // ToDo :  when rest api placed in uncomment this validation check
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  useEffect(() => {
    if (accessToken && email) {
      dispatch(validateTokenRequest(accessToken, email));
    } else {
      history.push('/auth/resetPassword');
    }
  }, [dispatch, accessToken, email, history]);

  useEffect(() => {
    if (
      validationMessage &&
      validationMessage.type === AuthStoreActionTypes.VALIDATE_TOKEN_REQUEST &&
      validationMessage.email === email &&
      validationMessage.message === AUTH_MESSAGES.FAILED_TOKEN_VALIDATION
    ) {
      setExpWarnModalOpen(true);
    }
  }, [validationMessage, email]);

  return (
    <Box display="flex" flexDirection="column" gap={2} p={16}>
      <WelcomeBackIcon />
      <Typography>Create new password</Typography>
      <Typography variant="caption" color={lightBlack}>
        Your new password must be different than your previous passwords.
      </Typography>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (accessToken && email) {
            dispatch(resetPassword(email, values.password, accessToken));
          }
        }}
      >
        {({ handleSubmit, values, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Field name="password">
              {({
                field,
              }: {
                field: {
                  name: string;
                  value: any;
                  onChange: (e: React.ChangeEvent<any>) => void;
                  onBlur: (e: React.FocusEvent<any>) => void;
                };
              }) => (
                <TextField
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  label="New Password"
                  placeholder="Enter new password"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickShowPassword}>
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </IconButton>
                    ),
                  }}
                />
              )}
            </Field>
            {values.password && (
              <PasswordStrengthChecker password={values.password} />
            )}
            <Field name="confirmPassword">
              {({
                field,
              }: {
                field: {
                  name: string;
                  value: any;
                  onChange: (e: React.ChangeEvent<any>) => void;
                  onBlur: (e: React.FocusEvent<any>) => void;
                };
              }) => (
                <TextField
                  {...field}
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm Password"
                  placeholder="Confirm new password"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickShowConfirmPassword}>
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEyeSlash : faEye}
                        />
                      </IconButton>
                    ),
                  }}
                />
              )}
            </Field>
            {values.confirmPassword && (
              <PasswordStrengthChecker password={values.confirmPassword} />
            )}
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
              disabled={!isValid}
              sx={{ mt: 5 }}
            >
              Confirm
            </Button>
          </Form>
        )}
      </Formik>
      <WarningModal
        message="The link to reset your password has expired. Please try again."
        open={isExpWarnModalOpen}
        onClose={() => {
          setExpWarnModalOpen(false);
          history.push('/auth/resetPassword');
        }}
      />
    </Box>
  );
};

export default CreatePasswordCard;
